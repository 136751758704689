<template>
  <div class="confirmWrap">
		<!-- 确认领取 -->
		<div class="confirm">
			<div class="confirm-equityInfo">
				<div class="box-top">
					<div class="title">
						<img class="icon"  :src="cardItem.item_icon"/>
						<div class="title-text">
							<div class="text name">{{cardItem.item_name == "20元生活缴费券" ? cardItem.item_name : cardItem.item_long_name}}</div>
							<div class="text price">价值￥{{cardItem.item_name == "20元生活缴费券" ? 20 : cardItem.item_price}}</div>
						</div>
					</div>
				</div>
				
				<div class="box-bottom">
					<div class="box-bottom-content">
						<div class="bottom-content-title">
							兑换须知
						</div>
						<div class="bottom-content-text">
							<div>{{cardItem.item_detail}}</div>
						</div>
					</div>
					<!-- 卡片下边框条 -->
					<div class="box-bottom-border"></div>
				</div>
			</div>
			<div class="confirm-btn">
				<div class="noexchange btn" v-if="!exchangeStatus" @click="receiveClick()">
					确认领取
				</div>
				<div class="exchange btn" v-else>
					已领取
				</div>
			</div>
		</div>
    <v-alert
      :visibility.sync="alert.visibility"
      :title="alert.title"
      :message="alert.message"
      :rulestype="alert.rulestype"
      @handleSubmit="handleSubmit"
    />
	</div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { equityReceive } from "@/api/taiyue/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
    VAlert
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      exchangeStatus:false,
      buyItemStatusTip:'',
      cardItem:{},
	  vipName:''
    };
  },
  computed: {
    ...mapGetters(["taiyue_token"]),
  },
  created() {
    const { item = {} ,vipName = ""} = this.$route.query;
    this.cardItem = JSON.parse(item);
    this.vipName = vipName;
    console.log(this.cardItem)
  },
  methods: {
    async receiveClick() {
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await equityReceive(this.taiyue_token,{ 'item_name': this.cardItem.item_name, item_require: this.vipName })
        .then(res =>{
          if (res.code == 0) {
              this.buyItemStatusTip = '领取成功';
              this.exchangeStatus = true;
              this.alert = {
                visibility: true,
                message: res.message,
                title: this.buyItemStatusTip,
              };
              this.$toast.clear();
          } else {
              this.buyItemStatusTip = '领取失败';
              this.alert = {
                visibility: true,
                message: res.message,
                title: this.buyItemStatusTip,
              };
              this.$toast.clear();
          }
        }).catch(err =>{
          this.$toast.clear();
        })
    },
    handleSubmit(){
      this.visibility = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.confirmWrap {
		width: 100%;
		height: 100%;
		color: #333333;
		.confirm{
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			font-size: 24.271px;
			color: #999999;
			background-color: #fff;
			.confirm-equityInfo{
				width: 100%;
				position: relative;
				margin-top: 30px;
				overflow-y: scroll;
				box-sizing: border-box;
				font-size: 24.271px;
				color: #999999;
				
				.box-top {
					width: 100%;
					height: 300px;
					background: url('https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/bg_01.png')  100%/cover no-repeat;
					overflow: hidden;
					color: #999999;
					.title {
						width: 100%;
						height: 140px;
						padding: 10px 60px;
						box-sizing: border-box;
						margin-top: 30px;
						.icon {
							width: 138px;
							height: 138px;
							// display: inline-block;
							float: left;
							margin-right: 18px;
							vertical-align: middle;
						}
						.title-text {
							width: calc(100% - 300px);
							height: 138px;
							float: left;
							margin-right: 18px;
							vertical-align: middle;
							.text {
								font-weight: 400;
								line-height: 40px;
								font-size: 26px;
							}
							.name{
								font-size: 34px;
								color: #000000;
								margin: 20px 0;
							}
						}
						.title-right{
							width: 120px;
							height: 72px;
							float: right;
							font-size: 28px;
							color: #FF5000;
							line-height: 72px;
						}
					} 
				}
				.box-bottom{
					width: 100%;
					margin-top: -70px;
					z-index: 2;
				}
				.box-bottom-content{
					width: 91.6%;
					min-height: 30px;
					max-height: 500px;
					overflow-y: scroll;
					background-color: #fff;
					box-sizing: border-box;
					margin: 0 auto;
					padding: 0 30px;
					.bottom-content-title{
						color: #333333;
						font-size: 30px;
						font-weight: 500;
						line-height: 42px;
						margin-bottom: 10px;
					}
					.bottom-content-text{
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 26px;
						line-height: 42px;
						text-align: justify;
						font-style: normal;
						white-space: pre-wrap;
					}
				}
				.box-bottom-border{
					width: 100%;
					height: 50px;
					background: url('https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/bg_02.png')  100%/cover no-repeat;
				}
			}
			.confirm-btn{
				width: 100%;
				height: 85px;
				position: absolute;
				bottom: 100px;
				
				.btn{
					width: 700px;
					height: 85px;
					margin: 0 auto;
					border-radius: 43px;
					font-weight: 500;
					font-size: 34px;
					color: #FFFFFF;
					line-height: 85px;
					text-align: center;
				}
				.noexchange{
					background-image: linear-gradient(to right, #FF5000 ,#FF7C00);
				}
				.exchange{
					background-color: #C9C9C9;
				}
			}
		}
	}
</style>
